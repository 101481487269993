import React from "react";

export default function About() {
  return (
    <section id="about">
      <div className="container mx-auto flex px-10 py-20 md:flex-row flex-col items-center">
        <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
          <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-white">
            Hi, I'm Alessandro Watellet.
            <br className="hidden lg:inline-block" />Alternance Full stack Javascript.
          </h1>
          <p className="mb-8 leading-relaxed">
          Bonjour, je suis en recherche d'une alternance dans le développement web en full stack ou backend en JavaScript sur Clermont-Ferrand et ses alentours. 
          Je suis aussi passionné par l'informatique, la cybersécurité et l'eSport. 
          L'alternance débuterait le 14 mars 2022 pour un minimum de 6 mois jusqu'à 14 mois maximum. Le rythme sera de 3 semaines en entreprise et 1 semaine en formation.
          </p>
          <div className="flex justify-center">
          <a
              href="https://www.linkedin.com/feed/update/urn:li:activity:6857285320858181632/"
              className="inline-flex text-white bg-green-500 border-0 py-2 px-6 focus:outline-none hover:bg-green-600 rounded text-lg">
              CV
            </a>
            <a
              href="#projects"
              className="ml-4 inline-flex text-gray-400 bg-gray-800 border-0 py-2 px-6 focus:outline-none hover:bg-gray-700 hover:text-white rounded text-lg">
              See My Past Work
            </a>
          </div>
        </div>
        <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
          <img
            className="object-cover object-center rounded"
            alt="hero"
            src=""
          />
        </div>
      </div>
    </section>
  );
}