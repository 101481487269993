export const projects = [
    {
        title: "CHAT IRC",
        subtitle: "React & Socket.io",
        description:
          "Refaire un chat irc en react et socket.io",
        image: "/img/irc2.png",
        link: "https://github.com/EpitechIT2020/W-JSC-502-MLN-2-1-irc-alessandro.watellet",
    },
    {
        title: "Freeads",
        subtitle: "Laravel",
        description:
          "Refaire un site de petites annonces avec le framework laravel",
        image: "/img/irc.png",
        link: "https://github.com/EpitechIT2020/W-PHP-502-MLN-2-1-FreeAds-alessandro.watellet",
      },
      {
        title: "Quizz",
        subtitle: "Symfony",
        description:
          "Faire un quizz avec le framework Symfony",
        image: "./project-3.gif",
        link: "https://github.com/EpitechIT2020/W-PHP-502-MLN-2-1-Quiz-vongsavanth.parry",
      },
];
    export const skills = [
        "JavaScript",
        "React",
        "React Native",
        "Node",
        "SQL",
        "MongoDB",
        "PHP",
        "Laraval",
        "Symfony",
        "HTML5",
        "CSS3",
        ".NET(C#)",
        "Wordpress",
      ];
